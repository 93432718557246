import {
    calculatePercentage,
    checkInPeriod,
    getCountDays,
    getNumberWithSpaces,
    shortenNumber
} from "../../../../../../utils";
import {useEffect} from "react";

interface IShorPlan {
    current: number,
    previous: number,
    general?: number,
    start?: string,
    end?: string
}
const ShortInfoOutput = ({
    title,
    value,
    fullValue,
    plan,
    side,
    today,
    customStyle
}: {
    title:string,
    value:any,
    fullValue:number,
    plan?: IShorPlan,
    side?: 'right'|'left'|'',
    today?: string,
    customStyle?:string
}) => {

    const PlanModal = ({plan, type}: {plan: IShorPlan, type: 'current' | 'prev'}) => {
        return (
            <div className={`dashboards-short-info-plan-modal ${side}`}>
                {type === 'current' && <div className='dashboards-short-info-plan-modal__title'>
                    <span className={`${+calculatePercentage(fullValue, plan.current, true) >= 100 ? 'green' : 'red'}`}>
                        {shortenNumber(+calculatePercentage(fullValue, plan.current, true), true)}%
                    </span> выполнение плана за текущий период
                </div>}
                {type === 'prev' && <div className='dashboards-short-info-plan-modal__title'>
                    <span>
                        {shortenNumber(+calculatePercentage(fullValue, plan.previous, true), true)}%
                    </span> по сравнению с предыдущим периодом
                </div>}
                {plan.general && <p className='dashboards-short-info-plan-modal__text'>
                    {shortenNumber(+calculatePercentage(fullValue, plan.general, true), true)}% от общего план-факта
                </p>}
                {plan.start && plan.end && today && checkInPeriod(today, plan.start, plan.end) &&
                    <p className='dashboards-short-info-plan-modal__text'>
                        На {today} показатель {title ? title : 'undefined'} должен быть не
                        менее {shortenNumber(plan.current / getCountDays(plan.start, plan.end), true)}, это
                        эквивалентно {calculatePercentage(plan.current / getCountDays(plan.start, plan.end), plan.current)}%
                        выполнения плана.
                    </p>}
                {today && <span className='dashboards-short-info-plan-modal__date'>{today}</span>}
            </div>
        )
    }

    return (
        <>
            <div className="dashboards-short-info__content">
                <div className="dashboards-short-info__title">{title ? title : 'undefined'}</div>
                <div className="dashboards-short-info__value">
                    {value[0]}<span className={customStyle ? customStyle : ''}>{value[1]}</span>
                    {fullValue > 1000 && <div className='dashboards-short-info__full'>
                        <span>{getNumberWithSpaces(fullValue.toFixed(2))}</span></div>}
                </div>
            </div>
            {plan && <div className="dashboards-short-info-plan">
                <span
                    className={`dashboards-short-info-plan__current ${+calculatePercentage(fullValue, plan.current, true) >= 100 ? 'green' : 'red'}`}>{shortenNumber(+calculatePercentage(fullValue, plan.current, true), true)}%</span>
                <PlanModal plan={plan} type={'current'} />
                {plan.previous && <span className='dashboards-short-info-plan__previous'>{shortenNumber(+calculatePercentage(fullValue, plan.previous, true), true)}%</span>}
                <PlanModal plan={plan} type={'prev'} />
            </div>}
        </>
    )
}

export default ShortInfoOutput;