import React, {useEffect, useState} from 'react'
import {useParams, useSearchParams, useNavigate} from "react-router-dom";

import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks'
import {
    getProjectAttributions,
    getProjectGeneral
} from "../../../../../../../actions/project";
import {currentUser, currentUserLang, userId} from "../../../../../../../redux/slices/user";
import {setBreadcrumbs} from "../../../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../../../redux/slices/sidebar";

import iconModel from '../../../../../../../assets/icons/model.svg'
import iconWindow from '../../../../../../../assets/icons/window.svg'

import Select from "../../../../../../../components/ui/Select";
import TimeFilter from "../../../../../../../components/ui/TimeFilter";
import {getScreenResolution, isDate, isNumber, parseFilter, QueueServer, sendSdk} from "../../../../../../../utils";

import cx from 'classnames'
import '../../../style.scss';
import iconPeriod from "../../../../../../../assets/icons/period.svg";
import ShortInfo from "../../../components/ShortInfo";
import DashboardsChart from "../../../components/Charts";
import {trans} from "../../../../../../../_locales";
import iconTarget from "../../../../../../../assets/icons/target-icon.svg";
import {getEvents} from "../../../../../../../actions/events";
import {eventsData, ICurrentEvents} from "../../../../../../../redux/slices/events";
import DashboardsTable from "../../../components/Table";
import {getSelectChangesTitle, onChangeGeneralSelect} from "../../../../../../../components/ui/Select/functions";
import {onChangeFilterQuery, onLoadingSelectDate, validateModel, validateScale, validateWindow} from "../../../functions";
import {Scales, Models, Windows, WindowsArr} from "../../../../../../../data";
import {
    clearFilter,
    dashboardsSettings, setFilter as setDispatchFilter,
    setSetting,
    setSettingEventTarget,
    setSettingModel, setSettingPeriod, setSettingScale, setSettingWindow
} from "../../../../../../../redux/slices/dashboards";
import {clearInstructions} from "../../../../../../../redux/slices/instructions";
import {getPages} from "../../../../../../../components/SideMenu/Role";
import {getEventTitles} from "../../../../Reports/functions";
import {IFilterMediaArray} from "../../../../../../../components/Tables/interfaces";
import {IModelAttribution, IWindowAttribution} from "../../../../../../../interfaces/common.d";
import {closeModal, ModalName, openModal} from "../../../../../../../redux/slices/modals";
import IconFilterWhite from "../../../../../../../assets/icons/icon-filter-white.svg";
import IconFilter from "../../../../../../../assets/icons/icon-filter-blue.svg";
import {Btn} from "../../../../../../../ui";
import { ReactComponent as CloseIcon } from '../../../../../../../assets/icons/icon-close-white.svg'

const GacMediaPerformance = () => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const language = useAppSelector(currentUserLang);
    const reduxEvents = useAppSelector(eventsData);
    const settings = useAppSelector(dashboardsSettings);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isViewGeneral = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_general');
    const isViewEcom = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_ecommerce');
    const isViewUserBehavior = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_user_behavior');

    const [firstLoading, setFirstLoading] = useState(true);
    const defaultPeriodStart = searchParams.get("ps") || '';
    const defaultPeriodEnd = searchParams.get("pe") || '';
    const defaultModel = searchParams.get("m") || '';
    const defaultWindow = searchParams.get("w") || '';
    const defaultScale = searchParams.get("s") || '';
    const defaultEventTargetActive = searchParams.get("et") || '';

    useEffect(()=> {
        if(projectId && reduxUserId) {
            sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'dashboard_general', 'resolution': getScreenResolution()}})
        }
    }, [projectId, reduxUserId])

    useEffect(() => {
        if(projectId) {
            const tempBreadcrumbs:any = [
                {
                  href: '/projects/',
                  title: 'Проект',
                },
                {
                  href: `/projects/${projectId}/reports/`,
                  title: trans('Project reporting', language)
                },
                {
                  href: `#`,
                  title: trans('Digital attribution modeling', language),
                  dataset: {}
                }
            ]
            if (isViewGeneral || (projectId && (+projectId === 12475))) {
                tempBreadcrumbs[2].dataset['general'] = {
                    title: trans('General indicators', language),
                    href: `/projects/${projectId}/reports/general/`
                }
            }
            if(isViewEcom || (projectId && (+projectId === 12475))) {
                tempBreadcrumbs[2].dataset['ecommerce'] = {
                    title: trans('E-Commerce Reports', language),
                    href: `/projects/${projectId}/reports/ecommerce/`
                }
            }
            if(isViewUserBehavior || (projectId && (+projectId === 12475))) {
                tempBreadcrumbs[2].dataset['user_behavior'] = {
                    title: trans('User behavior analysis', language),
                    href: `/projects/${projectId}/reports/user_behavior/`
                }
            }
            if(projectId && (+projectId === 12484 || +projectId===12483)) {
                tempBreadcrumbs[2].dataset = {};
                tempBreadcrumbs[2].dataset['getintent'] = {
                    title: 'GetIntent',
                    href: `/projects/${projectId}/reports/getintent/`
                }
            }
            if(projectId && (+projectId === 12523)) {
                tempBreadcrumbs[2].dataset['postview_sessions'] = {
                    title: 'PostView Sessions',
                    href: `/projects/${projectId}/reports/postview_sessions/`
                }
            }
            if(projectId && (+projectId === 12712)) {
                tempBreadcrumbs[2].dataset['gac_user_behavior'] = {
                    title: trans('Channel sequence efficiency', language),
                    href: `/projects/${projectId}/reports/gac_user_behavior/`
                }
            }
            if(projectId && (+projectId === 12712 || +projectId === 12777)) {
                tempBreadcrumbs[2].dataset['plan_fact'] = {
                    title: trans('Plan fact', language),
                    href: `/projects/${projectId}/reports/plan_fact/`
                }
            }
            dispatch(setBreadcrumbs(tempBreadcrumbs));
            dispatch(clearInstructions());
            dispatch(setSidebar(getPages({
                projectId: Number(projectId),
                access: permissions && permissions['can_view'],
                language,
                active: 'dashboards'
            })))
            dispatch(getProjectGeneral({currentUserId: reduxUser.id,projectId: Number(projectId)}));

            if(validateScale(defaultScale)) {
                setCategorize(defaultScale)
            } else if(settings && settings.categorize) {
                setCategorize(validateScale(settings.categorize, 'day'))
            } else {
                setCategorize('day');
            }
            if(reduxUser && reduxUser.permission && !isViewGeneral) {
                navigate(`/projects/${projectId}/`);
            }
        }
    }, [dispatch, projectId, permissions]);

    useEffect(()=> {
        if(settings && Object.keys(settings).length > 0) {
            if (sessionStorage.getItem("settings")) {
                const sessionSettings = sessionStorage.getItem("settings");
                const parsed = sessionSettings && JSON.parse(sessionSettings);
                const temp = {
                    period: settings.period ? settings.period : parsed.period,
                    model: settings.model ? settings.model : parsed.model,
                    window: settings.window ? settings.window : parsed.window,
                    categorize: settings.categorize ? settings.categorize : parsed.categorize,
                    events: settings.events ? settings.events : parsed.events
                }
                sessionStorage.setItem("settings", JSON.stringify(temp));
            } else sessionStorage.setItem("settings", JSON.stringify(settings));
        } else if(sessionStorage.getItem("settings")) {
            const sessionSettings = sessionStorage.getItem("settings");
            const parsed = sessionSettings && JSON.parse(sessionSettings);
            parsed && dispatch(setSetting(parsed));
        }
    }, [settings])

    const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow');

    //-------Filter--------
    const [periodStart, setPeriodStart] = useState(defaultPeriodStart);
    const [periodEnd, setPeriodEnd] = useState(defaultPeriodEnd);
    const [model, setModel] = useState<IModelAttribution>(validateModel(defaultModel, 'mtd'));
    const [attribution_window, setWindow] = useState<IWindowAttribution>(validateWindow(defaultWindow, 30) || 30);
    const [categorize, setCategorize] = useState(validateScale(defaultScale));
    const [selectDate, setSelectDate] = useState<string>(defaultPeriodStart&&defaultPeriodEnd ? `${defaultPeriodStart} - ${defaultPeriodEnd}` : trans('Select the period', language));
    const [eventTarget, setEventTarget] = useState('');
    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
        dispatch(setSettingPeriod({
            start: response.start,
            end: response.end
        }))
    }
    const datasetModel = Models(language);
    const [datasetWindow, setDatasetWindow] = useState(Windows(language, model));
    const datasetCategorize = Scales(language);

    const [datasetEventTarget, setDatasetEventTarget] = useState<any>( {})
    const [filterEventTarget, setFilterEventTarget] = useState<Array<ICurrentEvents>>( [])
    const [eventTargetActive, setEventTargetActive] = useState<Array<string>>( [])
    const onChangeEventTarget = (response:object) => {
        const {title, listActive} = getSelectChangesTitle(response, trans('All event targets', language));
        setEventTarget(title);
        setEventTargetActive(listActive)
    };
    useEffect(()=>{
        if(reduxEvents && Array.isArray(reduxEvents)) {
            const result:Array<ICurrentEvents> = [];
            reduxEvents.map((item:any)=> {
                const temp: ICurrentEvents = {} as ICurrentEvents;
                if(eventTargetActive.includes(item.event_target_name)) {
                    temp.event_name = item.event_name;
                    temp.event_type = item.event_type;
                    temp.event_category = item.event_category;
                    temp.event_target_name = item.event_target_name;
                    temp.event_target_price = item.event_target_price;
                    result.push(temp);
                }
            })
            dispatch(setSettingEventTarget(result));
            setFilterEventTarget(result);
        }
    }, [reduxEvents, eventTarget, eventTargetActive])
    useEffect(() => {
        dispatch(getEvents({projectId, calcAttr: true})).then((r) => {
            if (r.payload && typeof r.payload.length === 'number' && Array.isArray(r.payload)) {
                const temp:any = {};
                r.payload.map((item:any)=> (temp as any)[item.event_target_name] = item.event_target_name);
                setDatasetEventTarget(temp);
                const sessionSettings = sessionStorage.getItem("settings");
                const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                if(parseSettings && Array.isArray(parseSettings.events)) {
                    const {title, keys} = getEventTitles(parseSettings.events);
                    setEventTarget(title);
                    setEventTargetActive(keys);
                    setFilterEventTarget(parseSettings.events)
                }
            }
        });
    }, [dispatch, language, projectId]);

    useEffect(() => {
        if(firstLoading) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
                if(r.payload) {
                    const sessionSettings = sessionStorage.getItem("settings");
                    const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                    let model;
                    if(validateModel(defaultModel)) {
                        model = defaultModel;
                        setModel(defaultModel as IModelAttribution)
                    } else if(parseSettings && parseSettings.model) {
                        const temp = validateModel(parseSettings.model, 'fl');
                        model = temp;
                        setModel(temp)
                    } else {
                        const temp = validateModel(r.payload.attribution_model, 'fl');
                        model = temp;
                        setModel(temp);
                    }
                    if(defaultWindow && isNumber(defaultWindow) && WindowsArr(model).includes(+defaultWindow as IWindowAttribution)) {
                        setWindow(+defaultWindow as IWindowAttribution)
                    } else if(parseSettings && parseSettings.window && validateWindow(parseSettings.window, undefined, model)) {
                        setWindow(parseSettings.window)
                    } else {
                        const temp = validateWindow(r.payload.attribution_window, 30, model)
                        if(temp)
                            setWindow(temp);
                    }
                    if(validateScale(defaultScale)) {
                        setCategorize(defaultScale)
                    } else if(parseSettings && parseSettings.categorize) {
                        setCategorize(validateScale(parseSettings.categorize, 'day'))
                    } else setCategorize('day');
                    if(isDate(defaultPeriodStart) && isDate(defaultPeriodEnd)) {
                        setPeriodStart(defaultPeriodStart);
                        setPeriodEnd(defaultPeriodEnd);
                        setSelectDate(`${defaultPeriodStart} - ${defaultPeriodEnd}`);
                    } else if (parseSettings && parseSettings.period) {
                        setPeriodStart(parseSettings.period.start);
                        setPeriodEnd(parseSettings.period.end);
                        setSelectDate(`${parseSettings.period.start} - ${parseSettings.period.end}`);
                    } else
                        onLoadingSelectDate({period: r.payload.attribution_period, setPeriodStart, setPeriodEnd, setValue: setSelectDate});
                }
            }).catch((e: any)=> {
                const sessionSettings = sessionStorage.getItem("settings");
                const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                let model;
                if(validateModel(defaultModel)) {
                    model = defaultModel;
                    setModel(defaultModel as IModelAttribution)
                } else if(parseSettings && parseSettings.model) {
                    const temp = validateModel(parseSettings.model, 'fl');
                    model = temp;
                    setModel(temp)
                }
                if(defaultWindow && isNumber(defaultWindow) && WindowsArr(model).includes(+defaultWindow as IWindowAttribution)) {
                    setWindow(+defaultWindow as IWindowAttribution)
                } else if(parseSettings && parseSettings.window && validateWindow(parseSettings.window, undefined, model)) {
                    setWindow(parseSettings.window)
                }
                if (validateScale(defaultScale)) {
                    setCategorize(defaultScale)
                } else if (parseSettings && parseSettings.categorize) {
                    setCategorize(validateScale(parseSettings.categorize, 'day'))
                } else {
                    setCategorize('day');
                }
                if (isDate(defaultPeriodStart) && isDate(defaultPeriodEnd)) {
                    setPeriodStart(defaultPeriodStart);
                    setPeriodEnd(defaultPeriodEnd);
                    setSelectDate(`${defaultPeriodStart} - ${defaultPeriodEnd}`);
                } else if (parseSettings && parseSettings.period) {
                    setPeriodStart(parseSettings.period.start);
                    setPeriodEnd(parseSettings.period.end);
                    setSelectDate(`${parseSettings.period.start} - ${parseSettings.period.end}`);
                } else
                    onLoadingSelectDate({period: 'month', setPeriodStart, setPeriodEnd, setValue: setSelectDate});
            })
            if(defaultEventTargetActive) {
                let name = defaultEventTargetActive;
                if(name === 'all_target_events') {
                    name = trans('All event targets', language)
                }
                setEventTarget(name);
                setEventTargetActive(defaultEventTargetActive.split(','))
            }
        }
    }, [projectId, reduxUser.id])

    //-------End Filter--------
    // ------Filter--------
    const [filter, setFilter] = useState<IFilterMediaArray>([]);
    const [filterActive, setFilterActive] = useState(false);
    const onResetFilter = () => {
        dispatch(clearFilter());
        setFilter([]);
        setFilterActive(false);
    }
    const onResetEvents = () => {
        setEventTarget('');
        setEventTargetActive([]);
        setFilterEventTarget([])
    }
    const onSubmitFilter = (response:IFilterMediaArray) => {
        setFilter(response);
        if(response.length > 0) {
            setFilterActive(true);
            sendSdk('event', {
                'event_type': 'filter',
                'event_category': 'campaign_efficiency',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId
                }
            });
        }
        else setFilterActive(false);
        dispatch(closeModal(ModalName.FILTER))
    }
    //-------End Filter--------

    const queue = new QueueServer();

    //Hide header when scroll
    const [lastScroll, setLastScroll] = useState(0);
    const defaultOffset = 200;
    const containHide = (elem:HTMLElement) => elem.classList.contains('hide');

    const handleScroll = (event:any) => {
        const { scrollTop, scrollHeight, offsetHeight } = event.target;
        const header = document.querySelector('header');
        const body = document.querySelector('body');
        const content = document.querySelector('.page-content');
        if(body && header && content && scrollTop > lastScroll && !containHide(header) && scrollTop > defaultOffset && (scrollTop < scrollHeight - offsetHeight - 200)) {
            header.classList.add('hide');
            content.classList.add('stretch');
            body.classList.add('no-scroll');
        } else if(body && header && content && scrollTop < lastScroll && containHide(header)) {
            header.classList.remove('hide');
            content.classList.remove('stretch');
            body.classList.remove('no-scroll');
        }
        setLastScroll(scrollTop);
    }

    const onChangeQuery = ({
        periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter
    }:{
        periodStart?: string,
        periodEnd?: string,
        model?: IModelAttribution,
        attr_window?: IWindowAttribution,
        scale?: string,
        eventTargetActive?: Array<string>,
        filter?: IFilterMediaArray
    }) => {
        const query = onChangeFilterQuery({periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter});
        const temp = {
            search: query
        }
        navigate(temp);
    };

    useEffect(()=> {
        if(!firstLoading) {
            onChangeQuery({
                periodStart,
                periodEnd,
                model,
                attr_window: attribution_window,
                scale: categorize,
                eventTargetActive
            });
        }
    }, [periodStart, periodEnd, model, attribution_window, categorize, eventTargetActive])

    useEffect(() => {
        if(validateModel(model)) {
            setDatasetWindow(Windows(language, model));
            if(!validateWindow(attribution_window, undefined, model)) {
                setWindow(30);
            }
        }
    }, [model]);

  return (
      <div className={contentClassName} onScroll={() => null}>
          <div className="project-filter">
              <div className="project-filter__date">
                  <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
              </div>
              <div className="project-filter__window">
                  <Select
                      selectActive={`${trans('Scale', language)}: ${(datasetCategorize as any)[categorize]}`}
                      icon={iconPeriod}
                      dataset={datasetCategorize}
                      onChangeActive={(res: any) => onChangeGeneralSelect(res, (value: any) => {
                          setCategorize(value);
                          dispatch(setSettingScale(value))
                      })}/>
              </div>
              <div className="dashboard-page-filter__select">
                  <Select
                      selectActive={eventTarget ? eventTarget : trans('All event targets', language)}
                      icon={iconTarget}
                      dataset={datasetEventTarget}
                      onChangeActive={onChangeEventTarget}
                      checkbox={true}
                      listActive={eventTargetActive}
                      isSelectAllBtn={true}
                  />
              </div>
              <div className="dashboard-page-filter__auto">
                  <button className={`dashboard-page-filter__btn ${filterActive ? 'active' : ''}`} onClick={() => {
                      projectId && dispatch(openModal({
                          name: ModalName.FILTER,
                          data: {
                              onSubmit: onSubmitFilter,
                              projectId: +projectId
                          }
                      }))
                  }}>
                      <img src={filterActive ? IconFilterWhite : IconFilter} alt=""/>
                      {/*{trans('Filter', language)}*/}
                  </button>
                  {filterActive && <Btn
                      className={'dashboard-page-filter__reset'}
                      isIconBtn={true}
                      size='sm'
                      color='transparent'
                      onClick={onResetFilter}
                      icon={{
                          Component: CloseIcon,
                          width: 16,
                          height: 16
                      }}
                  />}
              </div>
          </div>
          <div className="project-summary">
              <div className="project-summary__item">
                  <ShortInfo
                      name={'gac_index_cost'}
                      titleDefault={trans('gac_index_cost', language)}
                      projectId={Number(projectId)}
                      periodStart={periodStart}
                      periodEnd={periodEnd}
                      // model={model}
                      // window={attribution_window}
                      targetEvents={filterEventTarget}
                      filter={filter}
                      queue={queue}
                  />
              </div>
              <div className="project-summary__item">
                  <ShortInfo
                      name={'gac_index_impression'}
                      titleDefault={trans('gac_index_impression', language)}
                      projectId={Number(projectId)}
                      periodStart={periodStart}
                      periodEnd={periodEnd}
                      filter={filter}
                      queue={queue}
                  />
              </div>
              <div className="project-summary__item">
                  <ShortInfo
                      name={'gac_index_click'}
                      titleDefault={trans('gac_index_click', language)}
                      projectId={Number(projectId)}
                      periodStart={periodStart}
                      periodEnd={periodEnd}
                      filter={filter}
                      queue={queue}
                  />
              </div>
              <div className="project-summary__item">
                  <ShortInfo
                      name={'gac_index_last_click_lead'}
                      titleDefault={trans('gac_index_last_click_lead', language)}
                      projectId={Number(projectId)}
                      periodStart={periodStart}
                      periodEnd={periodEnd}
                      model={model}
                      window={attribution_window}
                      targetEvents={filterEventTarget}
                      filter={filter}
                      queue={queue}
                  />
              </div>
              <div className="project-summary__item">
                  <ShortInfo
                      name={'gac_index_last_impression_lead'}
                      titleDefault={trans('gac_index_last_impression_lead', language)}
                      projectId={Number(projectId)}
                      periodStart={periodStart}
                      periodEnd={periodEnd}
                      model={model}
                      window={attribution_window}
                      targetEvents={filterEventTarget}
                      filter={filter}
                      queue={queue}
                  />
              </div>
              <div className="project-summary__item">
                  <ShortInfo
                      name={'gac_beh_index_uniq_chain'}
                      titleDefault={trans('gac_beh_index_uniq_chain', language)}
                      projectId={Number(projectId)}
                      periodStart={periodStart}
                      periodEnd={periodEnd}
                      model={model}
                      window={attribution_window}
                      targetEvents={filterEventTarget}
                      filter={filter}
                      queue={queue}
                  />
              </div>
          </div>
          <div className="project-block">
              <div className="project-block__item">
                  <div className="project__diagram">
                      <DashboardsChart
                          name={'gac_chart_lead_cpa'}
                          titleDefault={trans('gac_chart_lead_cpa', language)}
                          projectId={Number(projectId)}
                          periodStart={periodStart}
                          periodEnd={periodEnd}
                          type={'LineItems'}
                          model={model}
                          window={attribution_window}
                          categorize={categorize}
                          targetEvents={filterEventTarget}
                          filter={filter}
                          queue={queue}
                      />
                  </div>
              </div>
              <div className="project-block__item">
                  <div className="project__diagram">
                      <DashboardsChart
                          name={'gac_chart_media_dynamic'}
                          titleDefault={trans('gac_chart_media_dynamic', language)}
                          projectId={Number(projectId)}
                          periodStart={periodStart}
                          periodEnd={periodEnd}
                          type={'LineItems'}
                          // model={model}
                          // window={attribution_window}
                          categorize={categorize}
                          labelIndex={0}
                          filter={filter}
                          queue={queue}
                      />
                  </div>
              </div>
          </div>

          <div className="project-table">
              <div className="project__table">
                  <DashboardsTable
                      name={'gac_table_full_stat'}
                      projectId={Number(projectId)}
                      periodStart={periodStart}
                      periodEnd={periodEnd}
                      model={model}
                      window={attribution_window}
                      // categorize={categorize}
                      targetEvents={filterEventTarget}
                      filter={filter}
                      queue={queue}
                      hideTotal={true}
                  />
              </div>
          </div>
          <div className={'project-block'}>
              <div className="project-block__item">
                  <div className="project__diagram">
                      <DashboardsChart
                          name={'gac_funnel_media'}
                          titleDefault={trans('gac_funnel_media', language)}
                          projectId={Number(projectId)}
                          periodStart={periodStart}
                          periodEnd={periodEnd}
                          type={'Funnel'}
                          // model={model}
                          // window={attribution_window}
                          categorize={categorize}
                          labelIndex={0}
                          targetEvents={filterEventTarget}
                          filter={filter}
                          queue={queue}
                      />
                  </div>
              </div>
              <div className="dashboard-page-block__item">
                  <div className="dashboard-page-table">
                      <div className="dashboard-page__table">
                          <DashboardsTable
                              name={'gac_table_distr_impression_user'}
                              projectId={Number(projectId)}
                              periodStart={periodStart}
                              periodEnd={periodEnd}
                              model={model}
                              window={attribution_window}
                              // categorize={categorize}
                              targetEvents={filterEventTarget}
                              filter={filter}
                              queue={queue}
                              hideTotal={true}
                          />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default GacMediaPerformance;