import { createAsyncThunk } from '@reduxjs/toolkit'
/*eslint import/no-unresolved: [2, { amd: true }]*/
import { ItemStatus } from '../interfaces/common.d'
import {closeModal, ModalName, openModal} from '../redux/slices/modals'
import {downloadCsv, showPopup, updateToken} from '../utils'
import { contentJson } from '../data'
import {trans} from "../_locales";
import {getMarketingItem} from "./marketing";
import {ITableFunnelFilter} from "../components/Tables/TableFunnel";

interface DownloadParams {
  currentUserId?: number
  projectId: number
  campaignId: number
  refresh?: boolean
}

interface CreatParams {
  data: FormData | JSON,
  userId: number,
  projectId: number,
  campaignId: number
  marketingId?: number
  filter?: ITableFunnelFilter
}

interface DeleteParams {
  userId: number,
  projectId: number,
  campaignId: number,
  placementId: number,
}

interface MultiDeleteParams {
  projectId: number,
  campaignId: number,
  placementIds: Array<number>,
  language?: string
}

interface CsvParams {
  mode: 'all' | 'active' | number[]
  userId: number
  projectId: number
  campaignId: number
  type?: 'xlsx' | 'csv'
}

interface StatusParams {
  status: ItemStatus
  userId?: number
  projectId: number
  campaignId?: number
  placementId: number
  marketingId?: number
  filter?: ITableFunnelFilter
}

interface UpdateParams extends CreatParams, DeleteParams {}

export const downloadPlacementsData = createAsyncThunk(
  'placements/download',
  async ({projectId, campaignId}: DownloadParams , { rejectWithValue, extra: API}: any) => {
    try {
      const response = await API.get(`/api/placements/?campaign_id=${campaignId}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      // return response.data.data;
      return { projectId, campaignId, data: response.data };
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message);
    }
  }
);

export const creatPlacement = createAsyncThunk(
  'placements/creatPlacement',
  async (
    { data, userId, projectId, campaignId, marketingId, filter }: CreatParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      let url = `/api/placements/?campaign_id=${campaignId}`;
      if(marketingId) {
        url += `&marketing_campaign_id=${marketingId}`
      }
      const response = await API.post(
        url, data, contentJson);
        // `/api/user/${userId}/project/${projectId}/media/campaign/${campaignId}/placement`, data, contentJson);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.NEW_PLATFORM));
      showPopup(dispatch, response.data.message as string);
      dispatch(downloadPlacementsData({ projectId, campaignId }));
      if(marketingId) {
        dispatch(getMarketingItem({projectId: projectId, marketingId, filter}));
      }
      return response.data.data;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message);
    }
  }
);

export const deletePlacement = createAsyncThunk(
  'placements/deletePlacement',
  async (
    { userId, projectId, campaignId, placementId }: DeleteParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.delete(
        `/api/placements/${placementId}/`
      );
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.message as string);
      dispatch(downloadPlacementsData({ currentUserId: userId, projectId, campaignId, refresh: true }));
      return response.data.data;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message);
    }
  }
);

export const deleteMultiplePlacement = createAsyncThunk(
  'placements/deleteMultiplePlacement',
  async (
    { projectId, campaignId, placementIds, language='ru' }: MultiDeleteParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.delete(
        `/api/placements/?ids=${placementIds.join(',')}`
      );
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.message as string);
      dispatch(downloadPlacementsData({ projectId, campaignId }));
      if(response.data.errors) {
        dispatch(openModal({
          name: ModalName.MESSAGE,
          data: {
            title: trans('Error', language),
            message: response.data,
            buttons: {
              confirmation: {
                text: trans('Close', language),
                onClick: () => {
                  dispatch(closeModal(ModalName.MESSAGE));
                }
              },
            }
          }
        }))
      }
      return response.data.data;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message);
    }
  }
);

export const updatePlacementById = createAsyncThunk(
  'placements/updatePlacementById',
  async (
    { data, userId, projectId, campaignId, placementId, marketingId, filter }: UpdateParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(
        `/api/placements/${placementId}/`, data, contentJson);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.PLATFORM_EDITING));
      dispatch(downloadPlacementsData({ currentUserId: userId, projectId, campaignId}));
      if(marketingId) {
        dispatch(getMarketingItem({projectId: projectId, marketingId, filter}));
      }
      showPopup(dispatch, response.data.message as string);
      return response.data.data;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message);
    }
  }
);

export const downloadCampaignPixelsCsv = createAsyncThunk(
  'placements/downloadCampaignPixelsCsv',
  async (
    { mode, userId, projectId, campaignId, type='xlsx' }: CsvParams,
    { rejectWithValue, extra: API }: any
  ) => {
    try {
      let apiString;
      if (Array.isArray(mode) && mode.length > 0) {
        apiString = `/api/reports/${type}/?p_id=${projectId}&c_id=${campaignId}&cp_ids=${mode.join('&cp_ids=')}&cap=false`;
      } else if (mode === 'active') {
        apiString = `/api/reports/${type}/?p_id=${projectId}&c_id=${campaignId}&cap=true`;
      } else if (mode === 'all') {
        apiString = `/api/reports/${type}/?p_id=${projectId}&c_id=${campaignId}`;
      }

      if (apiString) {
        if(type === 'csv') {
          const response = await API.get(apiString);
          const new_token = response.headers["x-new-token"];
          updateToken(new_token);
          const fileName = response.headers['content-disposition'] && response.headers['content-disposition'].split('; ').find((item: string) => item.startsWith('filename=')).replace('filename=', '');
          try {
            downloadCsv(response.data, type, (fileName && `${fileName}_${new Date().toISOString().split('T')[0]}`) || `targetads_pixels_for_project_${projectId}_${new Date().toISOString().split('T')[0]}`);
          } catch (error) {
            console.error('Ошибка при скачивании файла', error);
          }
        } else {
          const response = await API.get(apiString, {responseType: 'blob'});
          const new_token = response.headers["x-new-token"];
          updateToken(new_token);
          const fileName = response.headers['content-disposition'] && response.headers['content-disposition'].split('; ').find((item: string) => item.startsWith('filename=')).replace('filename=', '');
          try {
            downloadCsv(response.data, 'xlsx', (fileName && `${fileName}_${new Date().toISOString().split('T')[0]}`) || `targetads_pixels_for_project_${projectId}_${new Date().toISOString().split('T')[0]}`);
          } catch (error) {
            console.error('Ошибка при скачивании файла', error);
          }
        }
      }
      return;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message);
    }
  }
);

export const changePlacementStatus = createAsyncThunk(
  'placements/changePlacementStatus',
  async (
    { status, userId, projectId, campaignId, placementId, marketingId, filter }: StatusParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const data = {'status':status};
      const response = await API.post(`/api/placements/${placementId}/status/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      if(campaignId) {
        dispatch(downloadPlacementsData({currentUserId: userId, projectId, campaignId, refresh: true}));
      }
      if(marketingId) {
        dispatch(getMarketingItem({projectId: projectId, marketingId, filter}));
      }
      return response.data.status;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message);
    }
  }
);


export const getPlacementsGenerals = createAsyncThunk(
  'placements/getCampaignsGenerals',
  async (
    { campaignId, placementIds }: {campaignId:number, placementIds:Array<number>},
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/campaigns/${campaignId}/placements/general/?ids=${placementIds.join(',')}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message);
    }
  }
);


export const updatePlacementsMulti = createAsyncThunk(
  'placements/updatePlacementsMulti',
  async (
    { data, projectId, campaignId, placementIds }: {
      data: any,
      projectId:number,
      campaignId:number,
      placementIds:Array<number>
    },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/campaigns/${campaignId}/placements/update/?ids=${placementIds.join(',')}`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(downloadPlacementsData({ projectId, campaignId}));
      return response.data;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message);
    }
  }
);
